export const borderTypes = [
    {
        value: 'top',
        content: 'Top',
    },
    {
        value: 'bottom',
        content: 'Bottom',
    },
    {
        value: 'both',
        content: 'Top & bottom',
    },
    {
        value: 'none',
        content: 'None',
    },
];

export const groupedAnimationTypes = [
    {
        value: 'bounceIn',
        content: 'Bounce In',
        group: 'Bounce',
    },
    {
        value: 'bounceInLeft',
        content: 'Bounce In Left',
        group: 'Bounce',
    },
    {
        value: 'bounceInRight',
        content: 'Bounce In Right',
        group: 'Bounce',
    },
    {
        value: 'bounceInUp',
        content: 'Bounce In Up',
        group: 'Bounce',
    },
    {
        value: 'bounceInDown',
        content: 'Bounce In Down',
        group: 'Bounce',
    },
    {
        value: 'fadeIn',
        content: 'Fade In',
        group: 'Fade',
    },
    {
        value: 'fadeInLeft',
        content: 'Fade In Left',
        group: 'Fade',
    },
    {
        value: 'fadeInRight',
        content: 'Fade In Right',
        group: 'Fade',
    },
    {
        value: 'fadeInUp',
        content: 'Fade In Up',
        group: 'Fade',
    },
    {
        value: 'fadeInDown',
        content: 'Fade In Down',
        group: 'Fade',
    },
    {
        value: 'rotateIn',
        content: 'Rotate Fade In',
        group: 'Rotate',
    },
    {
        value: 'rotateInDownLeft',
        content: 'Rotate Down Left Fade In',
        group: 'Rotate',
    },
    {
        value: 'rotateInUpLeft',
        content: 'Rotate Up Left Fade In',
        group: 'Rotate',
    },
    {
        value: 'rotateInDownRight',
        content: 'Rotate Down Right Fade In',
        group: 'Rotate',
    },
    {
        value: 'rotateInUpRight',
        content: 'Rotate Up right fade in',
        group: 'Rotate',
    },
    {
        value: 'zoomIn',
        content: 'Zoom in',
        group: 'Zoom',
    },
    {
        value: 'zoomInUp',
        content: 'Zoom In Up',
        group: 'Zoom',
    },
    {
        value: 'zoomInDown',
        content: 'Zoom In Down',
        group: 'Zoom',
    },
    {
        value: 'zoomInRight',
        content: 'Zoom In Right',
        group: 'Zoom',
    },
    {
        value: 'zoomInLeft',
        content: 'Zoom In Left',
        group: 'Zoom',
    },
    {
        value: 'slideInUp',
        content: 'Slide In up',
        group: 'Slide',
    },
    {
        value: 'slideInDown',
        content: 'Slide In down',
        group: 'Slide',
    },
    {
        value: 'slideInRight',
        content: 'Slide In right',
        group: 'Slide',
    },
    {
        value: 'slideInLeft',
        content: 'Slide in left',
        group: 'Slide',
    },
    {
        value: 'flipInX',
        content: 'Flip In Vertical',
        group: 'Other',
    },
    {
        value: 'flipInY',
        content: 'Flip In Horizontal',
        group: 'Other',
    },
    {
        value: 'rollIn',
        content: 'Roll In Left',
        group: 'Other',
    },
    {
        value: 'lightSpeedIn',
        content: 'Speedy Slide In Right',
        group: 'Other',
    },
    {
        value: 'none',
        content: 'None',
        group: 'Other',
    },
];

export function deepCopyObject(objectToCopy) {
    return JSON.parse(JSON.stringify(objectToCopy));
}

export function changeNestedObjectValue(keys, objectToChange, value) {
    const lastKey = keys[keys.length - 1];
    let resultObject = deepCopyObject(objectToChange);

    let currentObj = resultObject;

    for (let i = 0; i < keys.length - 1; i++) {
        currentObj = currentObj[keys[i]];
    }

    currentObj[lastKey] = value;

    return resultObject;
}

export function updateObjectProperties(objectToUpdate, changeContentCallbackArray) {
    let updatedObject = objectToUpdate;
    changeContentCallbackArray.forEach((contentCallback) => {
        const [value, ...args] = contentCallback;
        const filteredArgs = args.filter((arg) => Number.isInteger(arg) || Boolean(arg));
        updatedObject = changeNestedObjectValue(filteredArgs, updatedObject, value);
    });

    return updatedObject;
}

export function deepEqual(object1, object2) {
    if (!object1 || !object2) {
        return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}
